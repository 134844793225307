@import '../../../../scss/theme-bootstrap';

.three-column-grid {
  &__wrapper {
    margin: auto;
    max-width: $max-width-xlarge;
    &.padding-default-wrapper {
      padding: 30px 0;
      @include breakpoint($medium-up) {
        padding: 40px 20px;
      }
      .tout-block__wrapper-pad-default {
        padding-bottom: 0;
      }
    }
  }
  &__grid {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    @include breakpoint($medium-up) {
      flex-direction: row;
    }
    &-headline {
      display: block;
      text-align: #{$ldirection};
      width: 100%;
      margin-bottom: 30px;
      @include breakpoint($medium-up) {
        text-align: center;
      }
    }
    &-header {
      flex: 0 0 auto;
      @include breakpoint($medium-up) {
        flex: 0 0 50%;
      }
      @include breakpoint($large-up) {
        flex: 0 0 25%;
      }
      .content-block {
        &__line {
          &--header {
            .custom-text {
              line-height: 1.2;
            }
          }
          &--eyebrow,
          &--header {
            p {
              line-height: inherit;
            }
          }
        }
        &__link--wrapper,
        &__line--content {
          margin-top: 16px;
        }
        &__picture-wrapper {
          max-width: 80px;
        }
        &__link--bottom {
          flex-grow: 1;
          display: flex;
          align-items: flex-end;
          .content-block__line,
          .button {
            width: 100%;
          }
        }
        .header-above-grid & {
          .content-block {
            &__line {
              &--eyebrow,
              &--header {
                display: none;
              }
            }
          }
          &__line--content {
            margin: 0 0 10px 0;
          }
        }
      }
      .padding--default {
        padding: 0 20px 60px;
        @include breakpoint($medium-up) {
          @include swap-direction(padding, 0 0 0 20px);
        }
      }
    }
    &-carousel {
      position: relative;
      width: 100%;
      @include breakpoint($medium-up) {
        width: 50%;
      }
      @include breakpoint($large-up) {
        width: 75%;
      }
    }
    &-columns {
      display: flex;
      align-items: flex-start;
      width: 100%;
      &.slick-slider {
        margin-bottom: 0;
        .slick-list {
          width: 100%;
          height: 100%;
        }
        .slick-track {
          height: 100%;
        }
        &.carousel--no-peeking {
          .padding--bare {
            padding: 0 20px;
          }
        }
        &.carousel__peek-mobile {
          .slick-list {
            padding-#{$rdirection}: 30px;
            @include breakpoint($medium-up) {
              padding-#{$rdirection}: inherit;
            }
            .padding-default-wrapper & {
              padding: 0 20px;
              @include breakpoint($medium-up) {
                padding: 0;
              }
            }
          }
          .carousel-controls {
            .slick-prev,
            .slick-next {
              top: calc(50vw - 50px);
              @include breakpoint($medium-up) {
                top: 40%;
              }
            }
          }
        }
        &.carousel__peek-pc {
          .slick-list {
            @include breakpoint($medium-up) {
              padding-#{$rdirection}: 30px;
            }
          }
        }
      }
      &.disable-carousel {
        flex-wrap: wrap;
      }
    }
    &-item {
      flex: 0 0 100%;
      margin-bottom: 20px;
      @include breakpoint($medium-up) {
        margin-bottom: 0;
      }
      @include breakpoint($large-up) {
        height: 100%;
        flex: 0 0 calc(100% / 3);
      }
      &:last-of-type {
        margin-#{$rdirection}: 0;
      }
      &.padding--bare {
        @include swap_direction(padding, 0 20px 0 0);
        @include breakpoint($medium-up) {
          padding: 0 20px;
        }
      }
      .tout-block__media-wrapper {
        padding-top: 0;
      }
    }
    .carousel-dots {
      text-align: center;
      margin: 0;
      position: relative;
      width: 100%;
      &--color-black {
        .slick-dots {
          button {
            &::before {
              height: 1px;
              width: 12px;
              opacity: 1;
              background: $color-gray-on-light;
            }
          }
          .slick-active {
            button {
              &::before {
                height: 2px;
                background-color: $color-core-black;
              }
            }
          }
        }
      }
      &--color-white {
        .slick-dots {
          button {
            &::before {
              background-color: $color-core-dark-gray;
            }
          }
          .slick-active {
            button {
              &::before {
                background-color: $color-white;
              }
            }
          }
        }
      }
    }
    .carousel__slide {
      transition: all 0.333s cubic-bezier(0.65, 0.05, 0.36, 1);
    }
    .carousel-controls {
      .slick-prev,
      .slick-next {
        top: calc(50vw - 30px);
        width: 40px;
        height: 40px;
        background: $color-black;
        white-space: normal;
        @include breakpoint($medium-up) {
          @include transform(translateY(-40%));
          top: 40%;
          width: 80px;
          height: 80px;
        }
        &:before {
          content: '';
          position: relative;
          #{$ldirection}: 0;
          width: 100%;
          height: 14px;
          filter: brightness(1) invert(1);
        }
        &.slick-disabled {
          visibility: hidden;
        }
      }
      .slick-prev {
        #{$ldirection}: 0;
        @include breakpoint($medium-up) {
          #{$ldirection}: -20px;
        }
        &:before {
          background: url('#{$base-theme-path}svg-icons/src/icon--dropdown.svg') center no-repeat;
          transform: rotate(90deg);
        }
      }
      .slick-next {
        #{$rdirection}: 0;
        @include breakpoint($medium-up) {
          #{$rdirection}: -20px;
        }
        &:before {
          background: url('#{$base-theme-path}svg-icons/src/icon--dropdown.svg') center no-repeat;
          transform: rotate(-90deg);
        }
      }
    }
    .slick-dots {
      transition: all 0.333s cubic-bezier(0.65, 0.05, 0.36, 1);
      width: 100%;
      bottom: 0;
      #{$ldirection}: 0;
      position: relative;
      padding: 20px 0;
      button {
        height: 1px;
        opacity: 1;
        &::before {
          content: '';
          height: 1px;
          width: 12px;
          opacity: 1;
          background: $color-gray-on-light;
        }
      }
      .slick-active {
        button {
          &::before {
            height: 2px;
            background-color: $color-core-black;
          }
        }
      }
      li {
        height: 12px;
        width: 12px;
        margin: 0 4px;
      }
    }
    .carousel__borders-pc {
      .three-column-grid__grid-item {
        @include breakpoint($medium-up) {
          border-#{$ldirection}: 1px solid $color-gray-op15;
        }
        .dark-background & {
          border-color: $color-white-op15;
        }
      }
    }
  }
  &-border-bottom {
    border-bottom: 1px solid $color-gray-op15;
    &.dark-background {
      border-color: $color-white-op15;
    }
  }
}
